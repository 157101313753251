<template>
  <div style="background: #f5f5f5;height: 100%;
">
    <van-nav-bar
      style="background: #f5f5f5;"
      :title="title"
      left-arrow
      :fixed="true"
      :z-index="999"
      @click-left="back"
    ></van-nav-bar>
    <div style="overflow: hidden;background: #f5f5f5;padding-top:45px;height: 100%;">
      <div class="hed">
        <div class="toubu">
          <img v-if="userInfo.avatar" :src="userInfo.avatar" class="tou_img" alt />
          <img v-else src="../../assets/img/touxiang.png" class="tou_img" alt />
          <span class="name">{{userInfo.realName}}</span>
          <img class="xinbie" v-if="userInfo.sex == 1" src="../../assets/img/nan.png" alt />
          <img class="xinbie" v-if="userInfo.sex == 0" src="../../assets/img/nv.png" alt />
        </div>
        <div class="qy_code_wrapper">
          <vue-qr
            :text="link"
            :margin="10"
            colorDark="#000"
            colorLight="#fff"
            :dotScale="1"
            :logoSrc="img"
            :logoScale="0.2"
            :size="300"
          ></vue-qr>
        </div>
        <div class="h_name">扫一扫上面的二维码图案，参加活动</div>
      </div>
    </div>
    <van-button class="boto" type="danger" @click="tobanli">代朋友办理</van-button>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr
  },
  data() {
    return {
      gid: "",
      title: this.$route.query.title,
      link: this.$route.query.link,
      aid:this.$route.query.aid,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      img: require("../../assets/img/yicheju.png"),
      uid: "",
      list: {}
    };
  },
  methods: {},
  created() {},
  methods: {
    back() {
      this.$router.go(-1);
    },
    tobanli() {
      this.$router.push({
        path: "commission",
        query: {
          aid: this.aid
        }
      });
    }
  }
};
</script>
<style  lang="less" scoped>
#app {
  background: #f5f5f5;
  height: 100%;
}

.hed {
  height: 450px;
  background: #fff;
  margin: 80px 20px 0 20px;
  border-radius: 8px;
  padding: 20px;
}
.toubu {
  overflow: hidden;
}
.tou_img {
  width: 50px;
  height: 50px;
  float: left;
}
.name {
  float: left;
  margin-top: 5px;
  margin-left: 8px;
  font-size: 14px;
}
.qy_code_wrapper {
  margin-left: 12px;
  width: 300px;
  margin: auto;
  margin-top: 20px;
}
.qy_code_wrapper img {
  display: block;
  // text-align: center;
}
.h_name {
  text-align: center;
  color: #666;
  margin-top: 10px;
}
.xinbie {
  width: 14px;
  float: left;
  margin-top: 11px;
  margin-left: 8px;
}
.boto {
  position: fixed;
  bottom: 0;
  z-index: 99;
  left: 0;
  width: 100%;
  line-height: 40px;
}
</style>
